<template>
    <!-- register modal -->
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Registrieren</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <!-- f...book / mobile -->
                            <div class="col-12 d-xs-block d-md-none" style="min-width: 325px; max-width: 325px;">
                                <button
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="getFbProfile"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>
                            <!-- f...book / web -->
                            <div class="col-12 d-none d-md-block" style="padding: 0px 14px 0px 26px">
                                <button
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="getFbProfile"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>

                            <!-- g..gle / mobile -->
                            <div class="col-12 m-0 d-xs-block d-md-none" >
                                <div :ref="`${modalId}googleLoginBtnMobile`" :id="`${modalId}googleLoginBtnMobile`" ></div>
                            </div>
                            <!-- g..gle / web -->
                            <div class="col-12 m-0 d-none d-md-block" style="padding: 0px 26px 0px 26px">
                                <div :ref="`${modalId}googleLoginBtnWeb`" :id="`${modalId}googleLoginBtnWeb`" ></div>
                            </div>

                            <div v-if="userIsAlreadyRegistered" class="col-12 text-danger text-center" style="font-weight: bold; margin-top: 40px">
                                Dieser Benutzer ist bereits registriert!
                            </div>

                            <div class="col-12 px-0">
                                <div ref="googleLoginBtn" id="googleLoginBtn"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /register modal -->
</template>



<script>
    import BaseModal from "./BaseModal";
    //import Const from "../../constants";
    import {mixinBasics} from "../../mixins/basics";
    import {mapActions} from "vuex";
    import Configs from "../../configs";
    import jwt_decode from "jwt-decode";


    export default {
        name: "IdentifyViaExternalProvider",

        extends: BaseModal,

        mixins: [
            mixinBasics,
        ],

        props: {
            userKnownData: {
                type: Object,
                required: false,
                default: undefined
            }
        },

        data() {
            return {
                modalId: 'ident-via-external-provider',
                modal: undefined,
                isLoading: false,

                uid_google: undefined,
                uid_fb: undefined,
                accessTokenFb: undefined,
                email: undefined,
                firstname: undefined,
                lastname: undefined,
                userIsAlreadyRegistered: false
            }
        },

        methods: {
            ...mapActions('main', {
                _getFbProfile: 'getFbProfile'
            }),

            doIdentSuccess() {
                this.$emit('success', {
                    uid_google: this.uid_google,
                    uid_fb: this.uid_fb,
                    accessTokenFb: this.accessTokenFb,
                    email: this.email,
                    firstname: this.firstname,
                    lastname: this.lastname,
                });
                this.doClose();
            },

            doClose() {
                this.$emit('close');
            },

            async getFbProfile() {
                await window.FB.login(async (resFb) => {
                    if (resFb.status === 'connected' && resFb['authResponse'] !== undefined) {
                        // get profile data via BE
                        let res = await this._getFbProfile(resFb.authResponse.accessToken);
                        this.userIsAlreadyRegistered = res.isAlreadyRegisteredWithUs;

                        if (!this.userIsAlreadyRegistered) {
                            this.uid_google = undefined;    // reset possible google-id to prevent "UI-Button-chaos"
                            this.uid_fb = res.id;
                            this.email = res.email;
                            this.accessTokenFb = resFb.authResponse.accessToken;
                            this.doIdentSuccess();
                        }
                    }
                }, { scope: 'public_profile,email', return_scopes: true })
            },

            async createGoogleLogin(btnRef, btnWidth = 400) {
                await window.google.accounts.id.initialize({
                    client_id: Configs.googleAuthOptions.client_id,
                    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                    ux_mode: 'popup',
                    callback: (response) => {
                        this.getGoogleProfile(response);
                    },
                });
                await window.google.accounts.id.renderButton(
                    document.getElementById(btnRef),
                    {
                        theme: "outline",
                        size: "large",
                        width: btnWidth,
                    }  // customization attributes
                );
            },

            async getGoogleProfile(e) {
                // decode response
                let res = jwt_decode(e.credential);
                this.uid_fb = undefined;    // reset possible fb-id to prevent "UI-Button-chaos"
                this.uid_google = res.sub;
                this.firstname = res.given_name;
                this.lastname = res.family_name;
                this.email = res.email;
                this.doIdentSuccess();
            },

        },

        async created() {
            await this.createGoogleLogin(`${this.modalId}googleLoginBtnMobile`, 300);
            await this.createGoogleLogin(`${this.modalId}googleLoginBtnWeb`);
        }

    }
</script>



<style scoped>

</style>