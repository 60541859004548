<template>
    <!-- content -->
    <div class="container-fluid bg-lightblue">
        <div class="row">
            <div class="col-12">
                <div class="container py-5">
                    <div class="row mb-4">
                        <div class="col-12">
                            <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-registration-expert-title">Als Experte registrieren</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <!-- left side -->
                            <div class="container px-0 px-lg-3">
                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <button
                                            type="button"
                                            class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                            @click="showDlgIdentViaExternalProvider = true"
                                        >
                                            <span>Weiter mit <i class="fab fa-facebook-f"></i>acebook oder <i class="fab fa-google">oogle</i></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <span v-if="userIsAlreadyRegistered" class="text-danger" style="font-weight: bold">
                                            Unter dieser EMail-Adresse ist bereits ein Benutzer registriert!
                                        </span>

                                        <!-- email -->
                                        <span class="d-block my-5 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Oder registrieren mit E-Mail</span>
                                        <label
                                            for="registration-expert-email"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                            :class="$v.data.email.$error ? 'error-msg' : ''">
                                            E-MAIL ADRESSE *

                                            <span v-if="data.uid_fb" style="padding-left: 20px"> (<i class="fab fa-facebook-f" style="font-weight: bold"></i>acebook) </span>
                                            <span v-if="data.uid_google" style="padding-left: 20px"> (<i class="fab fa-google" style="font-weight: bold"></i>oogle) </span>
                                        </label>
                                        <input :disabled="data.uid_fb || data.uid_google" type="email" class="form-control" :class="$v.data.email.$error ? 'error-border' : 'mb-3'" id="registration-expert-email" v-model.trim="$v.data.email.$model" @change="checkEMailExists">
                                        <div v-if="$v.data.email.$error" class="error-msg">Bitte E-Mail-Adresse angeben</div>
                                        <!-- password -->
                                        <label
                                            for="registration-expert-password"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                            :class="$v.data.password.$error && !(data.uid_fb || data.uid_google) ? 'error-msg' : ''">KENNWORT *</label>
                                        <input :disabled="data.uid_fb || data.uid_google" type="password" class="form-control" :class="$v.data.password.$error ? 'error-border' : 'mb-3'" id="registration-expert-password" v-model.trim="$v.data.password.$model">
                                        <div v-if="$v.data.password.$error && !(data.uid_fb || data.uid_google)" class="error-msg">Bitte Passwort angeben</div>
                                        <!-- password repeat -->
                                        <label
                                            for="registration-expert-password-repeat"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                            :class="$v.data.passwordRepeat.$error  && !(data.uid_fb || data.uid_google) ? 'error-msg' : ''">KENNWORT WIEDERHOLEN *</label>
                                        <input :disabled="data.uid_fb || data.uid_google" type="password" class="form-control" :class="$v.data.passwordRepeat.$error ? 'error-border' : 'mb-3'" id="registration-expert-password-repeat" v-model.trim="$v.data.passwordRepeat.$model">
                                        <div v-if="$v.data.passwordRepeat.$error && !(data.uid_fb || data.uid_google)" class="error-msg">Bitte Passwort wiederholen</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        Felder mit <b> * </b> Markierung, sind Pflichtangaben
                                    </div>
                                </div>
                            </div>
                            <!-- /left side -->
                        </div>
                        <div class="col-12 col-lg-6">
                            <!-- right side -->
                            <div class="container px-0 px-lg-3">
                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Persönliche Daten</span>
                                        <div class="container px-0">
                                            <div class="row">
                                                <!-- salutation -->
                                                <div class="col-12 col-lg-6">
                                                    <label
                                                        for="registration-expert-salutation"
                                                        class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                        :class="$v.data.salutation.$error ? 'error-msg' : ''"
                                                    >ANREDE *</label>
                                                    <select
                                                        class="form-select"
                                                        id="registration-expert-salutation"
                                                        aria-label="Land"
                                                        v-model.trim="$v.data.salutation.$model"
                                                        :class="$v.data.salutation.$error ? 'error-border' : 'mb-3'"
                                                    >
                                                        <option value="Herr">Herr</option>
                                                        <option value="Frau">Frau</option>
                                                        <option value="Keine Angabe">Keine Angabe</option>
                                                    </select>
                                                    <div v-if="$v.data.salutation.$error" class="error-msg">Bitte Anrede auswählen</div>
                                                </div>

                                                <!-- title: leading-->
                                                <div class="col-12 col-lg-6">
                                                    <label
                                                        for="registration-expert-salutation"
                                                        class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                    >TITEL (vorangestellt)</label>
                                                    <select
                                                        class="form-select"
                                                        id="registration-expert-salutation"
                                                        aria-label="Land"
                                                        v-model.trim="data.title"
                                                    >
                                                        <option value="">[Auswählen]</option>
                                                        <option v-for="(t, idx) in academTitles.leading" :value="t" :key="`tl_${idx}`">{{t}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <!-- firstname -->
                                                <div class="col-12 col-lg-6">
                                                    <label
                                                        for="registration-expert-firstname"
                                                        class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                        :class="$v.data.firstname.$error ? 'error-msg' : ''">VORNAME *</label>
                                                    <input type="text" class="form-control" :class="$v.data.firstname.$error ? 'error-border' : 'mb-3'" id="registration-expert-firstname" v-model.trim="$v.data.firstname.$model">
                                                    <div v-if="$v.data.firstname.$error" class="error-msg">Bitte Vorname angeben</div>
                                                </div>
                                                <!-- lastname -->
                                                <div class="col-12 col-lg-6">
                                                    <label
                                                        for="registration-expert-lastname"
                                                        class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                        :class="$v.data.lastname.$error ? 'error-msg' : ''">NACHNAME *</label>
                                                    <input type="text" class="form-control" :class="$v.data.lastname.$error ? 'error-border' : 'mb-3'" id="registration-expert-lastname" v-model.trim="$v.data.lastname.$model">
                                                    <div v-if="$v.data.lastname.$error" class="error-msg">Bitte Nachname angeben</div>
                                                </div>

                                                <!-- title: trailing -->
                                                <div class="col-12 col-lg-6">
                                                    <label
                                                        for="registration-expert-salutation"
                                                        class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                    >TITEL (nachgestellt)</label>
                                                    <select
                                                        class="form-select"
                                                        id="registration-expert-salutation"
                                                        aria-label="Land"
                                                        v-model.trim="data.title_trailing"
                                                    >
                                                        <option value="">[Auswählen]</option>
                                                        <option v-for="(t, idx) in academTitles.trailing" :value="t" :key="`tt_${idx}`">{{t}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12 bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                        <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-registration-expert-form-section-title">Unternehmensdaten</span>
                                        <!-- company type -->
                                        <!--
                                        <input class="form-check-input" type="checkbox" id="registration-expert-student" v-bind:value="companyTypeCheckBoxValue" @click="toggleStudent($event.target.checked)">
                                        <label class="form-check-label mp-qks font-size-registration-expert-checkboxes mp-font-weight-medium ps-2 mb-3" for="registration-expert-student">
                                            in Ausbildung
                                        </label><br>
                                        -->

                                        <!-- company name -->
                                        <label for="registration-expert-company" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">FIRMENNAME</label>
                                        <input type="text" class="form-control mb-3" id="registration-expert-company" v-model.trim="data.company_title">

                                        <!-- company country -->
                                        <label
                                            for="registration-expert-country"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                            :class="$v.data.company_country.$error ? 'error-msg' : ''">LAND *</label>
                                        <select
                                            class="form-select"
                                            id="registration-expert-country"
                                            aria-label="Land"
                                            v-model.trim="$v.data.company_country.$model"
                                            :class="$v.data.company_country.$error ? 'error-border' : 'mb-3'"
                                        >
                                            <option selected value="">Auswählen</option>
                                            <option value="AT">Österreich</option>
                                            <option value="DE">Deutschland</option>
                                            <option value="CH">Schweiz</option>
                                            <option value="SE">Schweden</option>
                                        </select>
                                        <div v-if="$v.data.company_country.$error" class="error-msg">Bitte Land auswhälen</div>

                                        <!-- company address -->
                                        <!-- street -->
                                        <label
                                            for="registration-expert-street"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                            :class="$v.data.company_address.$error ? 'error-msg' : ''">STRAßE, HAUSNUMMER, STIEGE, TÜR-NR. *</label>
                                        <input type="text" class="form-control" :class="$v.data.company_address.$error ? 'error-border' : 'mb-3'" id="registration-expert-street" v-model.trim="$v.data.company_address.$model">
                                        <div v-if="$v.data.company_address.$error" class="error-msg">Bitte Adresse angeben</div>

                                        <div class="row">
                                            <!-- zip -->
                                            <div class="col-4 col-md-3">
                                                <label
                                                    for="registration-expert-zip"
                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                    :class="$v.data.company_zip.$error ? 'error-msg' : ''">PLZ *</label>
                                                <input type="text" class="form-control" :class="$v.data.company_zip.$error ? 'error-border' : 'mb-3'" id="registration-expert-zip" v-model.trim="$v.data.company_zip.$model">
                                                <div v-if="$v.data.company_zip.$error" class="error-msg">Bitte Postleitzahl angeben</div>
                                            </div>
                                            <!-- city -->
                                            <div class="col-8 col-md-9">
                                                <label
                                                    for="registration-expert-city"
                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                    :class="$v.data.company_city.$error ? 'error-msg' : ''">ORT *</label>
                                                <input type="text" class="form-control" :class="$v.data.company_city.$error ? 'error-border' : 'mb-3'" id="registration-expert-city" v-model.trim="$v.data.company_city.$model">
                                                <div v-if="$v.data.company_city.$error" class="error-msg">Bitte Stadt angeben</div>
                                            </div>
                                        </div>
                                        <label for="registration-expert-vatnr" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">UID-NUMMER</label>
                                        <div class="input-group mb-3">
                                            <select class="form-select phone-country-select" style="min-width: 85px" id="registration-expert-phone-areacode" aria-label="Land" v-model="data.company_tax_uid_prefix">
                                                <option value="ATU" selected>ATU</option>
                                                <option value="DE">DE</option>
                                                <option value="CHE">CHE</option>
                                                <option value="SE">SE</option>
                                            </select>
                                            <input type="text" class="form-control" id="registration-expert-vatnr" v-model="data.company_tax_uid">
                                        </div>

                                        <!--
                                        <label
                                            for="registration-expert-phone"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                            :class="$v.data.phone.$error || $v.data.phone_prefix.$error ? 'error-msg' : ''"
                                        >TELEFON</label>
                                        <div class="input-group" :class="$v.data.phone.$error || $v.data.phone_prefix.$error ? 'error-msg' : 'mb-3'">
                                            <select class="form-select phone-country-select" :class="$v.data.phone_prefix.$error ? 'error-border' : ''" id="registration-expert-phone-areacode" aria-label="Land" v-model="$v.data.phone_prefix.$model">
                                                <option value="+43" selected>+43</option>
                                                <option value="+49">+49</option>
                                                <option value="+41">+41</option>
                                            </select>
                                            <input type="text" class="form-control" :class="$v.data.phone.$error ? 'error-border' : ''" id="registration-expert-phone" v-model="$v.data.phone.$model">
                                        </div>
                                        <div v-if="$v.data.phone.$error && $v.data.phone_prefix.$error" class="error-msg">Bitte Vorwahl und Telefonnummer angeben</div>
                                        <div v-if="!$v.data.phone.$error && $v.data.phone_prefix.$error" class="error-msg">Bitte Vorwahl angeben</div>
                                        <div v-if="$v.data.phone.$error && !$v.data.phone_prefix.$error" class="error-msg">Bitte Telefonnummer angeben</div>
                                        -->

                                        <label
                                            for="registration-expert-phone"
                                            class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                        >TELEFON</label>
                                        <div class="input-group mb-3">
                                            <select class="form-select phone-country-select" id="registration-expert-phone-areacode" aria-label="Land" v-model="data.phone_prefix">
                                                <option value="+43" selected>+43</option>
                                                <option value="+49">+49</option>
                                                <option value="+41">+41</option>
                                                <option value="+46">+46</option>
                                            </select>
                                            <input type="text" class="form-control" id="registration-expert-phone" v-model="data.phone">
                                        </div>


                                        <label for="account-expert-about-me-search-tags"
                                               class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Nach
                                            Kassen (Mehrfachauswahl möglich)
                                        </label>
                                        <Select2
                                            id="account-expert-about-me-search-tags"
                                            aria-label="Such-Tags"
                                            v-model="data.insurances"
                                            :options="optionsInsurances"
                                            :settings="select2Settings"
                                        />
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :class="!accept_gdpr && $v.$dirty ? 'error-border' : 'mb-3'" id="registration-expert-accept-gdpr" v-model="accept_gdpr">
                                            <label
                                                class="form-check-label mp-qks font-size-registration-expert-checkboxes mp-font-weight-medium"
                                                for="registration-expert-accept-gdpr"
                                                :class="!accept_gdpr && $v.$dirty ? 'error-msg' : ''"
                                            >
                                                Ich akzeptiere die <a class="registration-expert-links" :href="`${documentsUrl}/AGB_merge_plate.pdf`" target="_blank">AGB</a>,
                                                die <a class="registration-expert-links" :href="`${documentsUrl}/Nutzungsbedingungen_merge_plate.pdf`" target="_blank">Nutzungsbedingungen</a> und
                                                <a class="registration-expert-links" :href="`${documentsUrl}/Datenschutzerklärung_merge_plate.pdf`" target="_blank">Datenschutzerklärung</a> *
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="registration-expert-accept-newsletter" v-model="data.newsletter">
                                            <label class="form-check-label mp-qks font-size-registration-expert-checkboxes mp-font-weight-medium" for="registration-expert-accept-newsletter">
                                                Ja, ich möchte von mergeplate.com produktbezogene Informationen per E-Mail erhalten.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12 col-lg-7 d-flex mb-3">
                                        <span class="align-self-center font-size-registration-expert-link-to-login mp-font-weight-semi-bold mp-qks">Bereits registriert? <a class="registration-expert-links text-decoration-none" style="cursor: pointer;" @click="switchToLogin">HIER ANMELDEN</a></span>
                                    </div>
                                    <div class="col-12 col-lg-5 d-flex flex-column justify-content-lg-end mb-3">
                                        <button
                                            type="button"
                                            class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5"
                                            @click="doRegister"
                                        >Registrieren</button>
                                    </div>
                                </div>
                            </div>
                            <!-- right side -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--- modals --->
        <RegisterExpertSuccessInfo v-if="showDlgRegisterSuccess" @close="gotoLandingPage" />
        <IdentifyViaExternalProvider v-if="showDlgIdentViaExternalProvider" @success="setExternalIdentData" @close="showDlgIdentViaExternalProvider = false" />

    </div>
    <!-- /content -->
</template>




<script>
    import {apiCall} from '../../helpers';
    import Configs from '../../configs';
    import {required, requiredUnless, sameAs, email, or} from 'vuelidate/lib/validators';
    import {isBoolean} from "lodash";
    import RegisterExpertSuccessInfo from "../../components/modals/RegisterExpertSuccessInfo";
    import {mixinBasics} from "../../mixins/basics";
    import {mapActions} from "vuex";
    //import jwt_decode from "jwt-decode";
    import eventBus from "../../eventbus";
    import Const from "../../constants";
    import IdentifyViaExternalProvider from "../../components/modals/IdentifyViaExternalProvider.vue";
    import Select2 from 'v-select2-component'

    const api = Configs.restApi.endpoints.map;
    const allowedFileTypes = '.gif, .jpg, .png, .jpeg, .pdf'
    const fileType = {
        doc: 'fas fa-file-pdf register-expert-file-upload-icon-pdf me-2',
        img: 'fas fa-file-image register-expert-file-upload-icon-image me-2',
        unknown: 'fas fa-file register-expert-file-upload-icon-image me-2'
    };
    const fileExt = [
        {type: 'application/pdf', iconClass: fileType.doc},
        {type: 'application/doc', iconClass: fileType.doc},
        {type: 'application/docx', iconClass: fileType.doc},
        {type: 'image/png', iconClass: fileType.img},
        {type: 'image/jpg', iconClass: fileType.img},
        {type: 'image/jpeg', iconClass: fileType.img},
        {type: 'image/gif', iconClass: fileType.img},
    ];
    const company_type_student = 1;
    const company_type_company = 2;



    export default {
        name: "ExpertRegister",

        metaInfo() {
            return {
                title: 'Als Experte bewerben',
                meta: [
                    { name: 'Als Experte bewerben | mergeplate.com' },
                    { title: 'Als Experte bewerben | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    'Als Experte bewerben | mergeplate.com',
                    'Als Experte bewerben - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit',
                    this.$route.path
                ))
            };
        },

        components: {
            IdentifyViaExternalProvider,
            RegisterExpertSuccessInfo,
            Select2
        },

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'expReg',
                showDlgRegisterSuccess: false,
                showDlgIdentViaExternalProvider: false,
                userIsAlreadyRegistered: false,

                files: [],
                maxFilesCnt: 5,

                accept_gdpr: false,
                data: {
                    salutation: undefined,
                    title: undefined,
                    title_trailing: undefined,
                    firstname: undefined,
                    lastname: undefined,
                    email: undefined,
                    uid_google: undefined,
                    uid_fb: undefined,
                    phone: undefined,
                    phone_prefix: undefined,
                    company_title: undefined,
                    company_type_id: company_type_company,
                    company_country: undefined,
                    company_address: undefined,
                    company_zip: undefined,
                    company_city: undefined,
                    company_tax_uid: undefined,
                    company_tax_uid_prefix: undefined,
                    newsletter: false,
                    password: undefined,
                    passwordRepeat: undefined,
                    insurances:undefined
                },
                allowedFileTypes,
                academTitles: Const.academicTitles
            };
        },

        validations() {
            return {
                data: {
                    salutation: { required },
                    firstname: { required },
                    lastname: { required },
                    email: {
                        requiredUnlessNotGoogleOrFb: or(requiredUnless('uid_google'), requiredUnless('uid_fb')),
                        email
                    },
                    //phone: { required },
                    //phone_prefix: { required },
                    //company_title: { required },
                    company_type_id: { required },
                    company_country: { required },
                    company_address: { required },
                    company_zip: { required },
                    company_city: { required },
                    password: {
                        requiredUnlessNotGoogleOrFb: or(requiredUnless('uid_google'), requiredUnless('uid_fb')),
                    },
                    passwordRepeat: {
                        requiredUnlessNotGoogleOrFb: or(requiredUnless('uid_google'), requiredUnless('uid_fb')),
                        sameAsPassword: sameAs('password')
                    },
                },
                //files: { required }
            };
        },

        computed: {
            fileList() {
                return this.files.map(f => {
                    let ic = fileExt.find(ext => ext.type === f.type);
                    return { name: f.name, iconClass: ic ? ic.iconClass : fileType.unknown };
                });
            },

            companyTypeCheckBoxValue() {
                return this.data.company_type_id === company_type_student;
            },

            optionsInsurances()
            {
                return this._insurances.map(e => { return {text: e.title, id: e.id}; });
            },

            select2Settings()
            {
                return {
                    multiple: true,
                    dropdownAutoWidth: false,
                    width: '100%',
                };
            },
        },

        methods: {
            ...mapActions('main', {
                _getFbProfile: 'getFbProfile'
            }),

            gotoLandingPage() {
                this.showDlgRegisterSuccess = false;
                this.$router.push('/');
            },

            async doRegister() {
                this.$v.$touch();
                if (this.$v.$invalid || !this.accept_gdpr || this.userIsAlreadyRegistered) return;
                let payload = this.buildMultipartPayload();
                let req = api.expert.register;
                await apiCall(req.url, req.method, payload);
                this.showDlgRegisterSuccess = true;
            },

            async checkEMailExists() {
                let req = api.expert.emailExistsCheck;
                let res = await apiCall(req.url, req.method,  {email: this.data.email});
                this.userIsAlreadyRegistered = res.data.email_exists;
            },

            buildMultipartPayload() {
                let payload = new FormData();
                let data = this.data;

                // do the phone#-merge-thing
                data.phone = `${this.data.phone_prefix ?? ''} ${this.data.phone ?? ''}`.trim();
                // data
                Object.keys(data).forEach((k) => {
                    let d = data[k];
                    // set booleans to 0 or 1 (otherwise they'll get transmitted as strings, like 'true' or 'false', which wont be recognized as 'boolean' by Laravel's validation)
                    d = isBoolean(d) ? (d ? 1 : 0) : d;
                    // omit empty values
                    if (d !== undefined) {
                        payload.append(k, d);
                    }
                });
                // files
                /*
                for (let i=0; i <= this.files.length-1; i++) {
                    payload.append(`file_${i}`, files[i]);
                }
                */
                return payload;
            },

            setExternalIdentData(identData) {
                Object.assign(this.data, identData);
            },

            toggleStudent(e) {
                this.data.company_type_id = e ? company_type_student : company_type_company;
            },

            /*
            addFile() {
                let file = this.$refs.docUpload.files[0];
                this.files.push(file);
            },

            removeFile(e) {
                this.files.splice(this.files.findIndex(f => f.name === e.name), 1);
            },
            */
            switchToLogin() {
                this.$router.push('/');
                eventBus.$emit(Const.events.SHOW_LOGIN, undefined);
                this.close();
            }
        },

        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>




<style scoped>
    .error-msg {
        color: red;
        margin-top: 0px;
    }
    .error-border {
        border: solid 1px red;
    }

</style>